import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _shuffle from 'lodash/shuffle';
import '../images/icons/firebase.svg';
import '../images/icons/gatsby.svg';
import '../images/icons/git.svg';
import '../images/icons/java.svg';
import '../images/icons/javascript.svg';
import '../images/icons/nodejs.svg';
import '../images/icons/react.svg';
import '../images/icons/typescript.svg';
import '../images/icons/vuejs.svg';

const DownloadsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: {eq: "icons"}, extension: { eq: "svg" } }) {
        edges {
          node {
            name
          }
        }
      }
    }
  `);
  return _shuffle(data.allFile.edges).map(file => (
    <svg key={file.node.name} className="mr-3">
      <use xlinkHref={`#${file.node.name}`} />
    </svg>
  ));
};
export default DownloadsPage;
