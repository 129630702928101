import React from 'react';
import Logo from './logo';
import github from '../images/brands/github.png';
import linkedin from '../images/brands/linkedin.svg';
import Social from './social';

const Footer = () => (
  <footer className="md:flex justify-between">
    <div className="inner mb-2">
      <Logo size="30px" className="mb-2" />
      <p>Takanoha Limited is a private limited company registered in England and Wales.</p>
      <p>Company number 11996892</p>
      <p>Flat 9 78 Redcliffe Gardens, London, United Kingdom, SW10 9HE</p>
    </div>

    <Social
      links={[{
        label: 'Github',
        image: <img src={github} alt="Github" style={{ objectFit: 'contain' }} />,
        link: 'https://github.com/githug',
      }, {
        label: 'Linkedin',
        image: <svg><use xlinkHref={`#${linkedin.id}`} /></svg>,
        link: 'https://www.linkedin.com/company/takanoha-limited',
      }]}
    />
  </footer>
);

export default Footer;
