import React from 'react';
import PropTypes from 'prop-types';

const Social = ({ links }) => (
  <div className="flex items-end pb-2">
    <div className="flex h-6">
      { links.map(({ label, image, link }) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          aria-label={label}
          style={{ width: '48px', height: '48px' }}
          className="mr-4 flex"
          key={label}
        >
          { image }
        </a>
      ))}
    </div>
  </div>
);

Social.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.element,
  })).isRequired,
};

export default Social;
