function* generator() {
  let index = 0;
  while (index < index + 1) {
    index += 1;
    yield index;
  }
}

const gen = generator();

export default prefix => `${prefix}-${gen.next().value}`;
