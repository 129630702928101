import React from 'react';
import PropTypes from 'prop-types';
import getUniqueId from '../utils';

const Logo = ({ size }) => {
  const titleId = getUniqueId('uniqueTitleID');
  const outerLayerId = getUniqueId('outerLayerId');
  const innerLayerId = getUniqueId('innerLayerId');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137.7 18.7"
      aria-labelledby={titleId}
      height={size}
    >
      <title id={titleId}>Takanoha Limited</title>
      <g id={outerLayerId} data-name="outer">
        <g id={innerLayerId} data-name="inner" className="fill-current">
          <path d="M.6,0a16,16,0,0,0,4,.3h8.1a17.7,17.7,0,0,0,4-.3,19.3,19.3,0,0,0-.3,3.3,6.7,6.7,0,0,0-2.7-.6H10.1v8.8c0,2.8.1,5.8.2,6.9H6.2a55.9,55.9,0,0,0,.3-7V2.6H3.4A14.4,14.4,0,0,0,0,3.1,24.4,24.4,0,0,0,.6,0Z" />
          <path d="M19.2,14.6h-.1l-1.3,3.6H14.3c.6-1,1.4-2.9,2.7-5.5l4.1-9.1c.5-.9.6-1.1.8-1.1s.3.2.7,1.1l4.6,9.2a46.5,46.5,0,0,0,2.9,5.4H25.8c-.4-.9-1.1-2.6-1.6-3.6h-5Zm4.1-2.1s.1,0,0-.1L21.6,8.3l-1.7,4.1c-.1.1-.1.1,0,.1Z" />
          <path d="M36.1,10a41.7,41.7,0,0,0,4.2-4.6c.6-.7,1.6-2.2,1.8-2.5h4.2a21.4,21.4,0,0,0-3,2.6L39.4,9.6a56.9,56.9,0,0,0,5.1,5.9,13.2,13.2,0,0,0,3.1,2.7l-1.9.2c-2.3,0-3.3-.1-5-2.2s-3.4-4.1-4.6-5.8v2a38.9,38.9,0,0,0,.3,5.9h-4a41.4,41.4,0,0,0,.3-6V8.8a35.4,35.4,0,0,0-.3-5.9h4a52.4,52.4,0,0,0-.3,5.9Z" />
          <path d="M52.1,14.6H52a30.1,30.1,0,0,0-1.2,3.6H47.2c.6-1,1.5-2.9,2.8-5.5l4.1-9.1c.4-.9.5-1.1.7-1.1s.3.2.8,1.1l4.5,9.2A46.5,46.5,0,0,0,63,18.3H58.7c-.4-.9-1.1-2.6-1.6-3.6h-5Zm4.1-2.1h.1L54.5,8.3l-1.7,4.1h3.4Z" />
          <path d="M67.2,18.3H65.4c.2-2,.4-11.1.4-14.6,0-1,0-1.1.2-1.1s.3.1.7.5c2.7,2.3,7.5,6.8,10.5,9,0-1.3-.1-6.2-.4-9.2h3.5a100.9,100.9,0,0,0-.5,11.9v2.9c0,.9,0,1-.2,1s-.7-.4-2.6-1.9-6.2-5.7-8.6-7.7c.1,3.9.2,7.6.4,9.2Z" />
          <path d="M83.8,10.7c0-4,2.7-8.1,8.7-8.1s8.5,3.5,8.5,7.6-3.1,8.4-8.7,8.4S83.8,14.3,83.8,10.7ZM97,11c0-3-1.5-6.4-5-6.4S87.7,6.2,87.7,10s1.6,6.6,5.1,6.6C94.5,16.6,97,15.6,97,11Z" />
          <path d="M104.9,8.8a36.2,36.2,0,0,0-.3-5.9h4a48.2,48.2,0,0,0-.2,5.5v.2h7.2V8.4a43.4,43.4,0,0,0-.3-5.5h4c-.1.8-.2,3-.2,5.9v3.6c0,2.3.1,4.9.2,5.9h-4a38.9,38.9,0,0,0,.3-5.9V11.3c0-.1,0-.1-.1-.1h-7c-.1,0-.1,0-.1.1v1.1c0,2.3.1,4.9.2,5.9h-3.9c.1-.9.2-3.3.2-5.9Z" />
          <path d="M126.8,14.6h-.1l-1.3,3.6h-3.5c.6-1,1.5-2.9,2.8-5.5l4.1-9.1c.4-.9.5-1.1.7-1.1s.3.2.7,1.1,2.6,5.2,4.6,9.2a46.5,46.5,0,0,0,2.9,5.4h-4.3c-.4-.9-1.1-2.6-1.6-3.6h-5Zm4.1-2.1s.1,0,0-.1l-1.7-4.1-1.7,4.1h3.4Z" />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  size: PropTypes.string,
};

Logo.defaultProps = {
  size: '20px',
};


export default Logo;
