import React from 'react';
import { graphql, Link } from 'gatsby';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import IconLister from '../components/iconLister';

const Index = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={['development', 'webapps', 'vue', 'react', 'java', 'software engineering', 'london']}
    />

    <div className="flex text-gray-200 items-center">
      <p className="flex-1 mr-4">
          Takanoha Limited is a London-based independent software engineering consultancy.
          It is run by Fredrik Mäkilä who has a passion for agile techniques,
          considers TDD to be best practice and always has DDD in mind when building projects.
          He prefers to work with the following technologies
      </p>

      <div className="text-center w-24 md:w-32 lg:w-48 xl:w-64">
        <Img fluid={data.file.childImageSharp.fluid} className="rounded-full" alt="Fredrik Mäkilä" />
      </div>
    </div>

    <div className="flex mb-12">
      <IconLister />
    </div>

    <div className="md:flex items-center">
      <p className="text-gray-200">Does this sound interesting to you?</p>
      <Link className="link" to="/contact">Contact me here.</Link>
    </div>
  </Layout>
);

export default Index;

export const query = graphql`
  query {
    file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

Index.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape(),
      }),
    }),
  }).isRequired,
};
